<template>
  <div>
    <div v-if="accommodation" class="bg-light p-2">
      <div class="mb-1">
        <h5>{{ accommodation.name || $t("Nom no disponible") }}</h5>
      </div>
      <div class="d-flex mb-50 flex-column">
        <span class="font-weight-bold">Adreça</span>
        <span>{{
          getFullAddress(accommodation.location) || $t("No disponible")
        }}</span>
      </div>
      <div class="d-flex mb-50 flex-column">
        <span class="font-weight-bold">Nom ETV</span>
        <span>{{
          accommodation.touristLicenceName || $t("No disponible")
        }}</span>
      </div>
      <div class="d-flex mb-50 flex-column">
        <span class="font-weight-bold">Nº ETV</span>
        <span>{{
          accommodation.touristLicenceNumber || $t("No disponible")
        }}</span>
      </div>
      <div class="d-flex mb-50 flex-column">
        <span class="font-weight-bold">Referència catastral</span>
        <span>{{
          accommodation.cadastralReference || $t("No disponible")
        }}</span>
      </div>
    </div>
    <div v-else class="bg-light p-2">No hi ha cap allotjament seleccionat</div>
  </div>
</template>

<script>
import { getFullAddress } from "@/utils/methods";
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      getFullAddress,
    };
  },
  computed: {
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    accommodation() {
      if (!this.accommodations.length) return null;
      return this.accommodations.find(
        (accommodation) => accommodation["@id"] === this.id
      );
    },
  },
};
</script>
