<template>
  <div id="new-contract">
    <b-card>
      <h4>Informació Bàsica</h4>
      <p class="text-muted">Intenta ser el més acurat possible</p>

      <!-- DATA INICI & FI -->
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Data d'inici" label-for="startDate">
            <b-form-datepicker
              id="startDate"
              v-model="startDate"
              placeholder="Inici de contracte"
              :locale="$i18n.locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Data de fi" label-for="endDate">
            <b-form-datepicker
              id="endDate"
              v-model="endDate"
              placeholder="Fi de contracte"
              :locale="$i18n.locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-checkbox v-model="autoRenewal">
            <small>El contracte s'ha de renovar automàticament cada any?</small>
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!-- PROPIETARI -->
      <b-row class="mt-2">
        <b-col cols="12" md="6">
          <b-form-group label="Propietari" label-for="owner">
            <v-select
              id="owner"
              v-model="selectedOwner"
              placeholder="Selecciona un propietari"
              :options="ownerOptions"
              :reduce="(option) => option.value"
              :loading="loadingOwners"
            >
              <template #option="{ label, idNumber }">
                <div>{{ label }}</div>
                <small>{{ idNumber }}</small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-checkbox v-model="ownerHasRepresentative" class="mb-1">
            <small>El propietari té un representant?</small>
          </b-form-checkbox>

          <div v-if="ownerHasRepresentative">
            <b-form-group
              label="Nom del representant"
              label-for="representativeFullName"
            >
              <b-form-input
                id="representativeFullName"
                v-model="representative.fullName"
              />
            </b-form-group>
            <b-form-group label="Tipus de document" label-for="idType">
              <b-form-radio-group
                id="idType"
                v-model="representative.idType"
                :options="idTypeOptions"
                text-field="label"
              />
            </b-form-group>
            <b-form-group
              label="Nº de document"
              label-for="representativeIdNumber"
            >
              <b-form-input
                id="representativeIdNumber"
                v-model="representative.idNumber"
              />
            </b-form-group>
          </div>

          <b-button
            variant="primary"
            size="sm"
            @click="onCreateNewOwnerClicked"
          >
            Crea un nou propietari
          </b-button>

          <b-modal
            id="new-owner-modal"
            centered
            size="lg"
            title="Crea un nou propietari"
            cancel-title="Cancel·la"
            ok-title="Guarda"
            :ok-disabled="!canSaveNewOwner"
            @ok="createNewOwner"
          >
            <owner-form v-model="newOwner" />
          </b-modal>
        </b-col>

        <b-col cols="12" md="6" class="mt-0 mt-md-2">
          <owner-details :id="selectedOwner" :representative="representative" />
        </b-col>
      </b-row>

      <!-- ALLOTJAMENT -->
      <b-row class="mt-2">
        <b-col cols="12" md="6">
          <b-form-group label="Allotjament" label-for="accommodation">
            <v-select
              id="accommodation"
              v-model="selectedAccommodation"
              placeholder="Selecciona un allotjament"
              :options="accommodationOptions"
              :reduce="(option) => option.value"
              :loading="loadingAccommodations"
            >
              <template #option="{ label, city }">
                <div>{{ label }}</div>
                <small>{{ city }}</small>
              </template>
            </v-select>
          </b-form-group>

          <b-button
            variant="primary"
            size="sm"
            @click="onCreateNewAccommodationClicked"
          >
            Crea un nou allotjament
          </b-button>

          <b-modal
            id="new-accommodation-modal"
            centered
            size="lg"
            title="Crea un nou allotjament"
            cancel-title="Cancel·la"
            ok-title="Guarda"
            :ok-disabled="!canSaveNewAccommodation"
            @ok="createNewAccommodation"
          >
            <accommodation-form v-model="newAccommodation" />
          </b-modal>
        </b-col>

        <b-col cols="12" md="6" class="mt-0 mt-md-2">
          <accommodation-details :id="selectedAccommodation" />
        </b-col>
      </b-row>

      <!-- FACTURACIÓ -->
      <b-row class="mt-2">
        <b-col cols="12" md="6">
          <b-form-group label="Tipus de facturació" label-for="billingType">
            <v-select
              id="billingType"
              v-model="billingType"
              placeholder="Selecciona un tipus de facturació"
              :options="billingTypeOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group
            label="% IVA del Propietari"
            label-for="ownerVatPercentage"
          >
            <b-form-input
              id="ownerVatPercentage"
              v-model="ownerVatPercentage"
              :disabled="billingType !== 'SUBURANCE'"
              number
              placeholder="% IVA"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group
            label="% IRPF del Propietari"
            label-for="ownerIrpfPercentage"
          >
            <b-form-input
              id="ownerIrpfPercentage"
              v-model="ownerIrpfPercentage"
              :disabled="billingType !== 'SUBURANCE'"
              number
              placeholder="% IRPF"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- CREAR CONTRACTE -->
      <b-row class="mt-4">
        <b-col class="d-flex justify-content-end">
          <b-button variant="secondary" class="mr-1" @click="cancel">
            Cancel·lar
          </b-button>
          <b-button variant="primary" @click="createNewContract">
            Crear
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BFormDatepicker,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BModal,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { notifyError } from "@/utils/methods";
import OwnerForm from "@/views/owners/new-owner/components/OwnerForm.vue";
import AccommodationForm from "@/views/accommodations/new-accommodation/components/AccommodationForm.vue";
import OwnerDetails from "@/views/contracts/new-contract/components/OwnerDetails.vue";
import AccommodationDetails from "@/views/contracts/new-contract/components/AccommodationDetails.vue";
import { idTypeOptions, billingTypeOptions } from "@/utils/select-options";

export default {
  name: "AddContract",
  components: {
    OwnerForm,
    BCol,
    BRow,
    AccommodationForm,
    OwnerDetails,
    AccommodationDetails,
    BCard,
    BFormGroup,
    vSelect,
    BFormDatepicker,
    BButton,
    BFormCheckbox,
    BModal,
    BFormInput,
    BFormRadioGroup,
  },
  data() {
    return {
      selectedOwner: null,
      newOwner: null,
      representative: {
        fullName: null,
        idType: null,
        idNumber: null,
      },
      selectedAccommodation: null,
      newAccommodation: null,
      startDate: null,
      endDate: null,
      autoRenewal: true,
      billingType: null,
      ownerVatPercentage: null,
      ownerIrpfPercentage: null,
      ownerHasRepresentative: false,
      idTypeOptions,
      billingTypeOptions,
    };
  },
  computed: {
    loadingOwners() {
      return this.$store.getters["owners/loading"];
    },
    owners() {
      return this.$store.getters["owners/owners"];
    },
    ownerOptions() {
      if (!this.owners.length) return [];
      return this.owners.map((owner) => ({
        label: owner.fullName,
        value: owner["@id"],
        idNumber: owner.idNumber,
      }));
    },
    loadingAccommodations() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    accommodationOptions() {
      if (!this.accommodations.length) return [];
      return this.accommodations.map((accommodation) => ({
        label: accommodation.name,
        value: accommodation["@id"],
        city: accommodation.location.city,
      }));
    },
    canSaveNewOwner() {
      return (
        !!this.newOwner?.firstName &&
        !!this.newOwner?.idType &&
        !!this.newOwner?.idNumber
      );
    },
    canSaveNewAccommodation() {
      return (
        !!this.newAccommodation?.touristLicenceName &&
        !!this.newAccommodation?.touristLicenceNumber
      );
    },
  },
  watch: {
    ownerHasRepresentative(value) {
      if (!value) {
        this.representative.fullName = null;
        this.representative.idType = null;
        this.representative.idNumber = null;
      }
    },
  },
  created() {
    if (!this.loadingOwners) this.fetchOwners();
    if (!this.loadingAccommodations) this.fetchAccommodations();
  },
  mounted() {
    this.initDateInputs();
  },
  methods: {
    fetchOwners() {
      this.$store
        .dispatch("owners/fetchOwners")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchOwners.title"),
            this.$t("errors.fetchOwners.description")
          )
        );
    },
    fetchAccommodations() {
      this.$store
        .dispatch("accommodations/fetchAccommodations")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
    initDateInputs() {
      const today = this.$moment();
      this.startDate = today.format("YYYY-MM-DD");
      this.endDate = today.endOf("year").format("YYYY-MM-DD");
    },
    onCreateNewOwnerClicked() {
      this.$bvModal.show("new-owner-modal");
    },
    onCreateNewAccommodationClicked() {
      this.$bvModal.show("new-accommodation-modal");
    },
    createNewOwner() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("owner/createOwner", {
          idType: this.newOwner.idType,
          idNumber: this.newOwner.idNumber,
          firstName: this.newOwner.firstName,
          lastName: this.newOwner.lastName || null,
          email: this.newOwner.email || null,
          phone: this.newOwner.phone || null,
          secondaryPhone: this.newOwner.secondaryPhone || null,
          nationality: this.newOwner.nationality || null,
          language: this.newOwner.language || null,
          location: this.newOwner.location || null,
        })
        .then((response) => {
          if (response.status === 201) {
            const owner = response.data;
            // Seleccionar aquest owner dins selectedOnwer
            this.selectedOwner = owner["@id"];
          } else {
            notifyError(
              "Error",
              "El propietari no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
            );
          }
        })
        .catch(() =>
          notifyError(
            "Error",
            "El propietari no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
        });
    },
    createNewAccommodation() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("accommodation/createAccommodation", {
          active: this.newAccommodation.active,
          name: this.newAccommodation.name,
          type: this.newAccommodation.type,
          cadastralReference: this.newAccommodation.cadastralReference,
          touristLicenceName: this.newAccommodation.touristLicenceName,
          touristLicenceNumber: this.newAccommodation.touristLicenceNumber,
          capacity: this.newAccommodation.capacity,
          location: this.newAccommodation.location,
        })
        .then((response) => {
          if (response.status === 201) {
            const accommodation = response.data;
            // Seleccionar aquest accommodation dins selectedAccommodation
            this.selectedAccommodation = accommodation["@id"];
          } else {
            notifyError(
              "Allotjament no creat",
              "L'allotjament no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
            );
          }
        })
        .catch(() =>
          notifyError(
            "Allotjament no creat",
            "L'allotjament no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
        });
    },
    cancel() {
      this.$router.push({ name: "foravila-contracts-list" });
    },
    createNewContract() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("contract/createContract", {
          startDate: this.startDate,
          endDate: this.endDate || null,
          autoRenewal: this.autoRenewal,
          billingType: this.billingType,
          managementType: "SHARED",
          ownerVatPercentage: this.ownerVatPercentage || 0,
          ownerIrpfPercentage: this.ownerIrpfPercentage || 0,
          accommodation: this.selectedAccommodation,
          representativeFullName: this.representative?.fullName || null,
          representativeIdType: this.representative?.idType || null,
          representativeIdNumber: this.representative?.idNumber || null,
          owner: this.selectedOwner,
        })
        .then((response) => {
          if (response.status === 201) {
            const contract = response.data;
            this.$router.push({
              name: "foravila-contract-view",
              params: { contractUuid: contract.uuid },
            });
          } else {
            notifyError(
              "Error",
              "El contracte no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
            );
          }
        })
        .catch(() =>
          notifyError(
            "Error",
            "El contracte no s'ha pogut crear correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
