<template>
  <div>
    <!-- BASIC INFO -->
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5>
          {{ $t("Informació bàsica") }}
        </h5>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Nom" :label-for="`firstName`">
          <b-form-input :id="`firstName`" v-model="owner.firstName" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Llinatges (opcional)" :label-for="`lastName`">
          <b-form-input :id="`lastName`" v-model="owner.lastName" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Email (opcional)" :label-for="`email`">
          <b-form-input :id="`email`" v-model="owner.email" type="email" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="3">
        <b-form-group label="Telèfon (opcional)" :label-for="`phone`">
          <b-form-input :id="`phone`" v-model="owner.phone" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="3">
        <b-form-group
          label="Telèfon secundari (opcional)"
          :label-for="`secondaryPhone`"
        >
          <b-form-input :id="`secondaryPhone`" v-model="owner.secondaryPhone" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Nacionalitat" :label-for="`nationality`">
          <v-select
            :id="`nationality`"
            v-model="owner.nationality"
            placeholder="Nacionalitat"
            :options="countryOptions"
            :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Idioma" :label-for="`language`">
          <v-select
            :id="`language`"
            v-model="owner.language"
            placeholder="Idioma"
            :options="languageOptions"
            :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Tipus de document" :label-for="`idType`">
          <b-form-radio-group
            :id="`idType`"
            v-model="owner.idType"
            :options="idTypeOptions"
            text-field="label"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6">
        <b-form-group label="Nº de document" :label-for="`idNumber`">
          <b-form-input :id="`idNumber`" v-model="owner.idNumber" />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- ADDRESS -->
    <b-row class="mt-2">
      <b-col cols="12">
        <b-location v-model="owner.location" hide-icons hide-gps hide-name />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
} from "bootstrap-vue";
import {
  idTypeOptions,
  countryOptions,
  languageOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";
import BLocation from "@foravila-core/components/b-location/BLocation.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    vSelect,
    BLocation,
  },
  props: {
    value: {
      type: Object,
      defualt: null,
    },
  },
  data() {
    return {
      owner: {
        firstName: null,
        lastName: null,
        idType: null,
        idNumber: null,
        email: null,
        phone: null,
        secondaryPhone: null,
        nationality: null,
        language: null,
        location: null,
      },
      countryOptions,
      idTypeOptions,
    };
  },
  computed: {
    languageOptions() {
      return languageOptions.filter((option) =>
        ["ca", "es", "en"].includes(option.value)
      );
    },
  },
  watch: {
    owner: {
      deep: true,
      handler(owner) {
        this.$emit("input", owner);
      },
    },
  },
  mounted() {
    this.initOwner();
  },
  methods: {
    initOwner() {
      this.owner.firstName = this.value?.firstName || null;
      this.owner.lastName = this.value?.lastName || null;
      this.owner.idType = this.value?.idType || null;
      this.owner.idNumber = this.value?.idNumber || null;
      this.owner.email = this.value?.email || null;
      this.owner.phone = this.value?.phone || null;
      this.owner.secondaryPhone = this.value?.secondaryPhone || null;
      this.owner.nationality = this.value?.nationality || null;
      this.owner.language = this.value?.language || null;
      this.owner.location = this.value?.location || null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
