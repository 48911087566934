<template>
  <div>
    <div v-if="owner" class="bg-light p-2">
      <div class="mb-1">
        <h5>{{ owner.fullName || $t("Nom no disponible") }}</h5>
      </div>
      <div class="d-flex mb-50 flex-column">
        <span class="font-weight-bold">Adreça</span>
        <span>{{ getFullAddress(owner.location) || $t("No disponible") }}</span>
      </div>
      <div class="d-flex mb-50 flex-column">
        <span class="font-weight-bold">Telòfon principal</span>
        <span>{{ owner.phone || $t("No disponible") }}</span>
      </div>
      <div class="d-flex mb-50 flex-column">
        <span class="font-weight-bold">Telòfon secundari</span>
        <span>{{ owner.secondaryPhone || $t("No disponible") }}</span>
      </div>
      <div v-if="owner.idType" class="d-flex flex-column">
        <span class="font-weight-bold">{{
          getIdTypeName(owner.idType, $i18n.locale)
        }}</span>
        <span>{{ owner.idNumber || $t("No disponible") }}</span>
      </div>
      <div v-if="representativeText" class="d-flex mt-50 flex-column">
        <span class="font-weight-bold">Representat per</span>
        <span>{{ representativeText }}</span>
      </div>
    </div>
    <div v-else class="bg-light p-2">No hi ha cap propietari seleccionat</div>
  </div>
</template>

<script>
import { getFullAddress, getIdTypeName } from "@/utils/methods";
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    representative: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      getFullAddress,
      getIdTypeName,
    };
  },
  computed: {
    owners() {
      return this.$store.getters["owners/owners"];
    },
    owner() {
      if (!this.owners.length) return null;
      return this.owners.find((owner) => owner["@id"] === this.id);
    },
    representativeText() {
      if (!this.representative) return null;
      const text = [];
      if (this.representative.fullName) text.push(this.representative.fullName);
      if (this.representative.idType && this.representative.idNumber) {
        text.push(
          `(${getIdTypeName(this.representative.idType)}: ${
            this.representative.idNumber
          })`
        );
      }
      return text.length > 0 ? text.join(" ") : null;
    },
  },
};
</script>
