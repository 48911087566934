<template>
  <b-row>
    <b-col cols="12" class="mb-2">
      <h5>
        {{ $t("Informació bàsica") }}
      </h5>
    </b-col>

    <!-- Name -->
    <b-col cols="12" sm="6" lg="4">
      <b-form-group label="Nom" label-for="name">
        <b-form-input
          id="name"
          v-model="accommodation.name"
          placeholder="Nom"
        />
      </b-form-group>
    </b-col>

    <!-- Accommodation Type -->
    <b-col cols="12" sm="6" lg="4">
      <b-form-group label="Tipus" label-for="accommodationType">
        <v-select
          id="accommodationType"
          v-model="accommodation.type"
          placeholder="Selecciona un tipus d'allotjament"
          :options="accommodationTypeOptions"
          :reduce="(option) => option.value"
        />
      </b-form-group>
    </b-col>

    <!-- Capacity -->
    <b-col cols="6" sm="6" lg="4">
      <b-form-group label="Capacitat" label-for="capacity">
        <b-form-spinbutton
          id="capacity"
          v-model="accommodation.capacity"
          min="1"
          max="20"
          step="1"
          placeholder="--"
        />
      </b-form-group>
    </b-col>

    <!-- Tourist Licence Name -->
    <b-col cols="12" sm="6" lg="4">
      <b-form-group label="Nom ETV" label-for="touristLicenceName">
        <b-form-input
          id="touristLicenceName"
          v-model="accommodation.touristLicenceName"
          placeholder="Nom ETV"
        />
      </b-form-group>
    </b-col>

    <!-- Tourist Licence Number -->
    <b-col cols="12" sm="6" lg="4">
      <b-form-group label="Nº ETV" label-for="touristLicenceNumber">
        <b-form-input
          id="touristLicenceNumber"
          v-model="accommodation.touristLicenceNumber"
          placeholder="Nº ETV"
        />
      </b-form-group>
    </b-col>

    <!-- Cadastral Reference -->
    <b-col cols="12" sm="6" lg="4">
      <b-form-group label="Ref. Catastral" label-for="cadastralReference">
        <b-form-input
          id="cadastralReference"
          v-model="accommodation.cadastralReference"
          placeholder="Nº Referència"
        />
      </b-form-group>
    </b-col>

    <!-- Location -->
    <b-col cols="12" class="mt-2">
      <b-location v-model="accommodation.location" hide-icons class="mb-3" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSpinbutton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { accommodationTypeOptions } from "@/utils/select-options";
import BLocation from "@foravila-core/components/b-location/BLocation.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BLocation,
    BFormSpinbutton,
    vSelect,
  },
  props: {
    value: {
      type: Object,
      defualt: null,
    },
  },
  data() {
    return {
      accommodation: {
        active: false,
        name: null,
        type: null,
        capacity: null,
        touristLicenceName: null,
        touristLicenceNumber: null,
        cadastralReference: null,
        location: null,
      },
      accommodationTypeOptions,
    };
  },
  watch: {
    accommodation: {
      deep: true,
      handler() {
        this.$emit("input", this.accommodation);
      },
    },
  },
  mounted() {
    this.initAccommodation();
  },
  methods: {
    initAccommodation() {
      this.accommodation.active = this.value?.active || false;
      this.accommodation.name = this.value?.name || null;
      this.accommodation.type = this.value?.type || null;
      this.accommodation.capacity = this.value?.capacity || null;
      this.accommodation.touristLicenceName =
        this.value?.touristLicenceName || null;
      this.accommodation.touristLicenceNumber =
        this.value?.touristLicenceNumber || null;
      this.accommodation.cadastralReference =
        this.value?.cadastralReference || null;
      this.accommodation.location = this.value?.location || null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
