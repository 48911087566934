<template>
  <div id="b-location">
    <!-- ADDRESS -->
    <b-row :class="{ 'mb-2': showGps }">
      <b-col v-if="showTitles" cols="12" class="mb-2">
        <h5 :class="titleClass">
          <feather-icon v-if="showIcons" icon="MapIcon" /> {{ $t("Adreça") }}
        </h5>
      </b-col>

      <b-col cols="12">
        <b-row>
          <!-- COUNTRY -->
          <b-col cols="12" sm="6" xl="3">
            <b-form-group :label="$t('País')" label-for="country">
              <v-select
                id="country"
                v-model="location.country"
                :options="countryOptions"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>

          <!-- REGION -->
          <b-col cols="12" sm="6" xl="3">
            <b-form-group :label="$t('Region')" label-for="region">
              <v-select
                id="region"
                v-model="location.region"
                :options="regionOptions"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>

          <!-- CITY -->
          <b-col cols="12" sm="6" xl="3">
            <b-form-group :label="$t('Ciutat')" label-for="city">
              <b-form-input id="city" v-model="location.city" />
            </b-form-group>
          </b-col>

          <!-- ZIP -->
          <b-col cols="12" sm="6" xl="3">
            <b-form-group :label="$t('Codi Postal')" label-for="zip">
              <b-form-input id="zip" v-model="location.zip" />
            </b-form-group>
          </b-col>

          <!-- ADDRESS TYPE -->
          <b-col cols="12" sm="6" xl="3">
            <b-form-group :label="$t('Tipus via')" label-for="addressType">
              <v-select
                id="addressType"
                v-model="location.addressType"
                :options="addressTypeOptions"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>

          <!-- IF DISSEMINATED -->
          <template v-if="isDisseminatedAddressType">
            <!-- ADDRESS POLYGON -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Polígon')" label-for="addressPolygon">
                <b-form-input
                  id="addressPolygon"
                  v-model="location.addressPolygon"
                />
              </b-form-group>
            </b-col>
            <!-- ADDRESS PLOT -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Parcel·la')" label-for="addressPlot">
                <b-form-input id="addressPlot" v-model="location.addressPlot" />
              </b-form-group>
            </b-col>
          </template>

          <!-- IF STANDARD ADDRESS -->
          <template v-else>
            <!-- ADDRESS NAME -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Nom Via')" label-for="addressName">
                <b-form-input id="addressName" v-model="location.addressName" />
              </b-form-group>
            </b-col>
            <!-- ADDRESS NUMBER -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Número')" label-for="addressNumber">
                <b-form-input
                  id="addressNumber"
                  v-model="location.addressNumber"
                />
              </b-form-group>
            </b-col>
            <!-- ADDRESS BLOCK -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Bloc')" label-for="addressBlock">
                <b-form-input
                  id="addressBlock"
                  v-model="location.addressBlock"
                />
              </b-form-group>
            </b-col>
            <!-- ADDRESS PORTAL -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Portal')" label-for="addressPortal">
                <b-form-input
                  id="addressPortal"
                  v-model="location.addressPortal"
                />
              </b-form-group>
            </b-col>
            <!-- ADDRESS STAIRS -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Escala')" label-for="addressStairs">
                <b-form-input
                  id="addressStairs"
                  v-model="location.addressStairs"
                />
              </b-form-group>
            </b-col>
            <!-- ADDRESS FLOOR -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Pis')" label-for="addressFloor">
                <b-form-input
                  id="addressFloor"
                  v-model="location.addressFloor"
                />
              </b-form-group>
            </b-col>
            <!-- ADDRESS DOOR -->
            <b-col cols="12" sm="6" xl="3">
              <b-form-group :label="$t('Porta')" label-for="addressDoor">
                <b-form-input id="addressDoor" v-model="location.addressDoor" />
              </b-form-group>
            </b-col>
          </template>

          <!-- LOCATION NAME -->
          <b-col v-if="showName" cols="12">
            <b-form-group
              :label="$t('Identificador de la ubicació')"
              label-for="name"
            >
              <b-form-input id="name" v-model="location.name" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- GPS -->
    <b-row v-if="showGps">
      <b-col v-if="showTitles" cols="12" class="mb-2">
        <h5 :class="titleClass">
          <feather-icon v-if="showIcons" icon="NavigationIcon" />
          {{ $t("GPS") }}
        </h5>
      </b-col>

      <b-col cols="12">
        <!-- COORDINATES -->
        <b-row class="mb-1">
          <!-- LATITUDE -->
          <b-col cols="12" sm="6" xl="3">
            <b-form-group :label="$t('Latitud')" label-for="latitud">
              <b-form-input id="latitud" v-model="location.latitude" />
            </b-form-group>
          </b-col>
          <!-- LONGITUDE -->
          <b-col cols="12" sm="6" xl="3">
            <b-form-group :label="$t('Longitud')" label-for="longitud">
              <b-form-input id="longitud" v-model="location.longitude" />
            </b-form-group>
          </b-col>

          <!-- AREA -->
          <b-col cols="12" sm="12" xl="6">
            <b-form-group :label="$t('Area')" label-for="area">
              <v-select
                id="area"
                v-model="location.area"
                :options="areaOptions"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- MAP -->
        <b-row>
          <b-col cols="12">
            <div class="map-container">
              <l-map
                ref="locationMap"
                :zoom="zoom"
                :center="mapCenter"
                :options="mapOptions"
              >
                <l-control-layers position="topright" />
                <l-tile-layer
                  v-for="tile in mapTiles"
                  :key="tile.name"
                  :name="tile.name"
                  :visible="tile.visible"
                  :url="tile.url"
                  layer-type="base"
                />
                <l-marker
                  :visible="mapMarker.visible"
                  :draggable="mapMarker.draggable"
                  :lat-lng.sync="mapMarker.position"
                />
              </l-map>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormInput, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import {
  placeTypeOptions,
  countryOptions,
  regionOptions,
  addressTypeOptions,
  areaOptions,
} from "@/utils/select-options";

// Map imports
import { LMap, LTileLayer, LMarker, LControlLayers } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    BFormGroup,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    hideIcons: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    hideGps: {
      type: Boolean,
      default: false,
    },
    hideTitles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // BASE CONFIG
      titleClass: "", // 'border-bottom pb-1',
      zoom: 12,
      mapOptions: { scrollWheelZoom: false },
      mapCenter: [39.77288941462659, 3.0153393745422363],
      mapMarker: {
        visible: true,
        draggable: true,
        position: { lat: 39.77288941462659, lng: 3.0153393745422363 },
      },
      mapTiles: [
        {
          name: "Mapa",
          visible: true,
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "Satèl·lit",
          visible: false,
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        },
      ],
      // DATA
      location: {
        country: null,
        region: null,
        city: null,
        zip: null,
        addressType: null,
        addressPolygon: null,
        addressPlot: null,
        addressName: null,
        addressNumber: null,
        addressBlock: null,
        addressPortal: null,
        addressStairs: null,
        addressFloor: null,
        addressDoor: null,
        latitude: null,
        longitude: null,
        area: null,
      },
      // OPTIONS PROVIDERS
      placeTypeOptions,
      countryOptions,
      regionOptions,
      addressTypeOptions,
      areaOptions,
    };
  },
  computed: {
    isDisseminatedAddressType() {
      return this.location?.addressType === "DISSEMINATED" || false;
    },
    showIcons() {
      return !this.hideIcons;
    },
    showGps() {
      return !this.hideGps;
    },
    showTitles() {
      return !this.hideTitles;
    },
    showName() {
      return !this.hideName;
    },
  },
  watch: {
    "mapMarker.position": function (position) {
      if (position) {
        this.location.latitude = position.lat;
        this.location.longitude = position.lng;
      }
    },
    location: {
      deep: true,
      handler() {
        this.$emit("input", {
          ...this.location,
          latitude: this.location?.latitude?.toString() || null,
          longitude: this.location?.longitude?.toString() || null,
        });
      },
    },
  },
  created() {
    if (this.value) this.initForm();
    if (this.showGps) {
      setTimeout(() => {
        this.$refs.locationMap.mapObject.invalidateSize();
      }, 100);
    }
  },
  methods: {
    initForm() {
      this.location = {
        name: this.value.name,
        country: this.value.country,
        region: this.value.region,
        city: this.value.city,
        zip: this.value.zip,
        addressType: this.value.addressType,
        addressPolygon: this.value.addressPolygon,
        addressPlot: this.value.addressPlot,
        addressName: this.value.addressName,
        addressNumber: this.value.addressNumber,
        addressBlock: this.value.addressBlock,
        addressPortal: this.value.addressPortal,
        addressStairs: this.value.addressStairs,
        addressFloor: this.value.addressFloor,
        addressDoor: this.value.addressDoor,
        latitude: this.value.latitude,
        longitude: this.value.longitude,
        area: this.value.area,
      };
      this.mapMarker.position = {
        lat: this.value.latitude,
        lng: this.value.longitude,
      };
      this.mapCenter = [this.value.latitude, this.value.longitude];
    },
    resetForm() {
      this.location = {
        name: null,
        country: null,
        region: null,
        city: null,
        zip: null,
        addressType: null,
        addressPolygon: null,
        addressPlot: null,
        addressName: null,
        addressNumber: null,
        addressBlock: null,
        addressPortal: null,
        addressStairs: null,
        addressFloor: null,
        addressDoor: null,
        latitude: null,
        longitude: null,
        area: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#b-location {
  .vs__dropdown-menu {
    z-index: 1001;
  }
  .map-container {
    height: 400px;
    width: 100%;
  }
}
</style>
